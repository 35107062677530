.left-right {
	display: flex;
	justify-content: space-between;

	&>* {
		align-content: center;
	}
	.tools {
		display: flex;
		gap: 2em;
		align-items: center;

	}
}
