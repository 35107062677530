@function hex-to-rgb($color) {
	@return #{red($color)}, #{green($color)}, #{blue($color)};
}

@mixin generate-brand-colors($brand-colors) {
	@each $color, $value in $brand-colors {
		--#{$color}-hex: #{$value};
		--#{$color}: #{hex-to-rgb($value)};
		--#{$color}-rgb: rgb(var(--#{$color}));
	}
}

$brand-colour-1: #0942C1;
$brand-colour-2: #9E47FF;
$brand-colour-3: #0FF4C6;

@import 'base';
@import 'light';
@import 'dark';


/* map to material theme */
:root {

	--theme-primary-colour: #{hex-to-rgb($brand-colour-1)};
	--theme-accent1-colour: #{hex-to-rgb($brand-colour-2)};
	--theme-accent2-colour: #{hex-to-rgb($brand-colour-3)};

	--theme-button-primary-colour: rgba(255, 255, 255, 1);
	--theme-button-primary-bg: var(--theme-primary-rgb);
	--theme-button-primary-border: none;

	--theme-button-secondary-colour: rgba(255, 255, 255, 1);
	--theme-button-secondary-bg: var(--theme-accent1-rgb);
	--theme-button-secondary-border:  none;

	--theme-button-tertiary-colour: rgba(255, 255, 255, 1);
	--theme-button-tertiary-bg: var(--theme-accent2-rgb);
	--theme-button-tertiary-border: none;

	--theme-button-success-colour: rgba(255, 255, 255, 1);
	--theme-button-success-bg: #4CAF50;
	--theme-button-success-border: none;

	--theme-button-warning-colour: rgb(255, 255, 255);
	--theme-button-warning-bg: rgb(252, 147, 9);
	--theme-button-warning-border: solid 2px rgba(228, 125, 40, 0.793);;

	--theme-button-danger-colour: rgba(255, 255, 255, 1);
	--theme-button-danger-bg: #F44336;
	--theme-button-danger-border: none;

	--theme-button-default-colour: rgba(255, 255, 255, 1);
	--theme-button-default-bg: #9E9E9E;
	--theme-button-default-border: none;

	.mat-mdc-table {
		--mat-table-background-color: var(--theme-panel-background);
		--mat-table-header-headline-color: var(--theme-text-regular-colour);
		--mat-table-row-item-label-text-color: var(--theme-text-regular-colour);
		--mat-table-row-item-outline-color: rgba(var(--theme-text-highlight-colour), 0.25);
	}
	.mat-mdc-card {
		--mdc-outlined-card-container-color: rgba(var(--theme-primary), 0.03);
		--mdc-elevated-card-container-color:  rgba(var(--theme-primary), 0.03);
		--mdc-elevated-card-container-elevation: var(--theme-elevation-shadow);
	}

}
