//https://www.google.com/design/spec/components/cards.html#cards-content-blocks
// Card resting elevation: 2dp
.card {
	border: 0;
	margin-bottom: 30px;
	margin-top: 30px;
	border-radius: $border-radius-large;
	color: var(--theme-text-regular-colour-rgb);
	background: var(--theme-panel-background-rgb);
	width: 100%;
	display: flex;
	flex-direction: column;

	.card-category:not([class*="text-"]) {
		color: $gray-color;
	}

	.card-category {
		margin-top: 10px;

		.material-icons {
			position: relative;
			top: 8px;
			line-height: 0;
		}
	}

	.card-title {
		margin-top: 0.625rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	// Cards have a default elevation of 2dp.
	@include box-shadow($bmd-shadow-2dp);
	font-size: .875rem;


	.card-body,
	.card-footer {
		padding: $padding-card-body-y $padding-card-body-x;
	}

	.card-body {
		&+.card-footer {
			padding-top: 0rem;
			border: 0;
			border-radius: $border-radius-large;
		}
	}

	.card-footer {
		display: flex;
		align-items: center;
		background-color: transparent;
		border: 0;

		.author,
		.stats {
			display: inline-flex;
		}

		.stats {
			color: $gray-color;

			.material-icons {
				position: relative;
				top: -10px;
				margin-right: 3px;
				margin-left: 3px;
				font-size: 18px;
			}
		}
	}

	@include media-breakpoint-up(lg) {

	}

	.card-header {
		border-bottom: none;
		background: transparent;

		.filters-bar {
			margin: 10px -15px -90px -15px;
			position: relative;
			filter: var(--theme-drop-shadow);

			mat-form-field {
				&>div:first-child {
					border-radius: 0 0 2px 2px;
				}

				&:has(+ mat-form-field) {
					&>div:first-child {
						border-bottom-right-radius: 0;
					}
				}

				&:not(:first-child) {
					&>div:first-child {
						border-bottom-left-radius: 0;
					}
				}

				.mdc-line-ripple {
					display: none;
				}
			}
		}

		&:has(.filters-bar) {
			margin-bottom: 40px;
		}

		.header-flex {
			display: flex;
			gap: 1em;
		}

		.header-icon-button {
			scale: 1.6;
			margin-top: 0.1em;
			color: white;
			margin-right: 0.5em;
			cursor: pointer;
			display: block;
			// background: radial-gradient(rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0));
			height: auto;

			&:active {
				transform: translateY(1px);
				background: none;
			}
		}

		&.with-actions {
			display: flex;
			justify-content: space-between;

			&>*:not(:first-child) {
				display: flex;
				gap: 1em;
				align-items: center;

				&>* {
					display: block;
					margin: 0;
				}
			}
		}

		.title {
			color: $white-color;
		}

		&:not([class*="card-header-"]) {
			@include shadow-big();
		}

		.nav-tabs {
			padding: 0;
		}

		&.card-header-image {
			position: relative;
			padding: 0;
			z-index: 1;
			margin-left: 15px;
			margin-right: 15px;
			margin-top: -30px;
			border-radius: $border-radius-large;

			img {
				width: 100%;
				border-radius: $border-radius-large;
				pointer-events: none;
				@include shadow-big-image();
			}

			.card-title {
				position: absolute;
				bottom: 15px;
				left: 15px;
				color: $white-color;
				font-size: $font-size-h4;
				text-shadow: 0 2px 5px rgba(33, 33, 33, 0.5);
			}

			.colored-shadow {
				transform: scale(0.94);
				top: 12px;
				filter: blur(12px);
				position: absolute;
				width: 100%;
				height: 100%;
				background-size: cover;
				z-index: -1;
				transition: opacity .45s;
				opacity: 0;
			}

			&.no-shadow {
				box-shadow: none;

				&.shadow-normal {
					@include shadow-big();
				}

				.colored-shadow {
					display: none !important;
				}
			}
		}
	}


	@mixin card-header($name, $backgroundGradient, $shadowColour, $headerTextColour) {

		.card-header-#{$name} .card-icon,
		.card-header-#{$name} .card-text,
		.card-header-#{$name}:not(.card-header-icon):not(.card-header-text),
		&.bg-#{$name},
		&.card-rotate.bg-#{$name} .front,
		&.card-rotate.bg-#{$name} .back {
			background: var(#{$backgroundGradient});

			.mdc-switch:enabled:focus:not(:active),
			.mdc-switch:enabled:hover,
			.mdc-switch:enabled {
				.mdc-switch__track::after {
					background: rgba(var(#{$shadowColour}), 0.5);
					filter: brightness(1.5);

				}
			}


			:not(.mat-mdc-form-field-infix)>label {
				color: #fff;
			}
		}

		.card-header-#{$name} .card-icon,
		.card-header-#{$name}:not(.card-header-icon):not(.card-header-text),
		.card-header-#{$name} .card-text {
			@include shadow-big-color-var($shadowColour);

		}
	}

	@include card-header(primary, --theme-card-primary-gradient, --theme-card-primary, --theme-card-primary-text);
	@include card-header(accent, --theme-card-accent-gradient, --theme-card-accent, --theme-card-accent-text);
	@include card-header(info, --theme-card-info-gradient, --theme-card-info, --theme-card-info-text);
	@include card-header(success, --theme-card-success-gradient, --theme-card-success, --theme-card-success-text);
	@include card-header(warning, --theme-card-warning-gradient, --theme-card-warning, --theme-card-warning-text);
	@include card-header(danger, --theme-card-danger-gradient, --theme-card-danger, --theme-card-danger-text);
	@include card-header(rose, --theme-card-rose-gradient, --theme-card-rose, --theme-card-rose-text);

	[class*="card-header-"],
	&[class*="bg-"] {
		color: $white-color;

		.card-title a,
		.card-title,
		.icon i {
			color: $white-color;
		}

		.icon i {
			border-color: rgba(255, 255, 255, 0.25);
		}

		.author a,
		.stats,
		.card-category,
		.card-description {
			color: $white-transparent;
		}

		.author a {

			&:hover,
			&:focus,
			&:active {
				color: $white-color;
			}
		}
	}

	.author {
		.avatar {
			width: 30px;
			height: 30px;
			overflow: hidden;
			border-radius: 50%;
			margin-right: 5px;
		}

		a {
			color: $black-color;
			text-decoration: none;

			.ripple-container {
				display: none;
			}
		}
	}

	&[class*="bg-"],
	&[class*="bg-"] .card-body {
		border-radius: $border-radius-large;

		h1,
		h2,
		h3 {
			small {
				color: $white-transparent;
			}
		}
	}

	.card-stats {
		background: transparent;
		display: flex;

		.author,
		.stats {
			display: inline-flex;
		}
	}
}

.card {
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);

	.card-title {
		margin-top: 0;
		margin-bottom: 15px;
	}

	.card-body {
		padding: $padding-card-body-y 20px;
		position: relative;
		height: calc(100% - 58px);
	}

	.card-header {
		z-index: 3 !important;

		.card-title {
			margin-bottom: 3px;
		}

		.card-category {
			margin: 0;
		}

		&.card-header-text {
			display: inline-block;

			&:after {
				content: "";
				display: table;
			}
		}

		&.card-header-icon,
		&.card-header-text {
			i {
				width: 33px;
				height: 33px;
				text-align: center;
				line-height: 33px;
			}

			.card-title {
				margin-top: 15px;
				color: $black-color;
			}

			h4 {
				font-weight: 300;
			}
		}

		&.card-header-tabs {
			.nav-tabs {
				background: transparent;
				padding: 0;
			}

			.nav-tabs-title {
				float: left;
				padding: 10px 10px 10px 0;
				line-height: 24px;
			}
		}
	}

	.card-actions {
		position: absolute;
		z-index: 1;
		top: -50px;
		width: calc(100% - 30px);
		left: 17px;
		right: 17px;
		text-align: center;

		.card-header {
			padding: 0;
			min-height: 160px;
		}

		.btn {
			padding-left: 12px;
			padding-right: 12px;
		}

		.fix-broken-card {
			position: absolute;
			top: -65px;
		}
	}

	.card-body+.card-footer,
	.card-footer {
		padding: 0;
		padding-top: 10px;
		margin: 0 15px 10px;
		border-radius: 0;
		justify-content: space-between;
		align-items: center;

		&>.group {
			display: flex;
			align-items: center;
			gap: 1em;
		}

		h6 {
			width: 100%;
		}

		.stats {
			color: #999999;
			font-size: 12px;
			line-height: 22px;

			.card-category {
				padding-top: 7px;
				padding-bottom: 7px;
				margin: 0;
			}

			.material-icons {
				position: relative;
				top: 4px;
				font-size: 16px;
			}
		}
	}

	[class*="card-header-"] {
		margin: 0px 15px 0;
		padding: 0;

		.card-title+.card-category {
			color: rgba(255, 255, 255, 0.62);

			a {
				color: $white-color;
			}
		}

		&:not(.card-header-icon):not(.card-header-text):not(.card-header-image) {
			border-radius: $border-radius-base;
			margin-top: -20px;
			padding: 15px;
		}

		.card-icon,
		.card-text {
			border-radius: $border-radius-base;
			background-color: $gray-color;
			padding: 15px;
			margin-top: -20px;
			margin-right: 15px;
			float: left;
		}

		.card-text {
			float: none;
			display: inline-block;
			margin-right: 0;

			.card-title {
				color: $white-color;
				margin-top: 0;
			}
		}

		position: relative;
	}

	[data-header-animation="true"] {
		@include transform-translate-y(0);
		-webkit-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
		-moz-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
		-o-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
		-ms-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
		transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
	}

	&:hover {
		[data-header-animation="true"] {
			@include transform-translate-y(-50px);
		}
	}

	.map {
		height: 280px;
		border-radius: $border-radius-large;
		margin-top: 15px;

		&.map-big {
			height: 420px;
		}
	}

	.card-body.table-full-width {
		padding: 0;
	}

	.card-plain .card-header-icon {
		margin-right: 15px !important;
	}
}

.card-dialog-panel {
	.mat-mdc-dialog-surface {
		background-color: transparent;

		&>.card,
		&>app-card>.card {
			margin: 35px 0 0 0;
		}
	}
}
