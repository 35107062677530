body {
	background-color: rgb(var(--theme-background-colour));
	color: rgb(var(--theme-text-regular-colour));
	font-weight: 300;
}

legend {
	border-bottom: 0;
}

.serif-font {
	font-family: $font-family-serif;
}

// Prevent highlight on mobile
* {
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
	-webkit-tap-highlight-color: transparent;

	&:focus {
		outline: 0;
	}
}

a {
	color: var(--theme-text-link-colour-rgb);

	&:hover,
	&:focus {
		filter: brightness(0.9);
		text-decoration: none;
	}

	&.text-info {

		&:hover,
		&:focus {
			color: darken($brand-info, 5%);
		}
	}

	& .material-icons {
		vertical-align: middle;
	}
}

/*           Animations              */
.animation-transition-general {
	@include transition-all($general-transition-time, $transition-linear);
}

.caret,
.sidebar a {
	@include transition-all($fast-transition-time, ease-in);
}

