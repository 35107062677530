$body-bg: #fafafa !default;

$font-family-sans-serif: 'Roboto', 'Helvetica', 'Arial', sans-serif !default;
$font-family-serif: 'Roboto Slab', 'Times New Roman', serif !default;

$font-size-base: 1rem !default;

$font-size-h1: 3.3125rem;
$font-size-h2: 2.25rem !default;
$font-size-h3: 1.5625rem;
$font-size-h4: 1.125rem !default;
$font-size-h5: 1.0625rem !default;
$font-size-h6: 0.75rem !default;
$font-paragraph: 14px !default;

$font-weight-light: 300 !default;
$font-weight-default: 400 !default;
$font-weight-bold: 500 !default;
$font-weight-extra-bold: 700 !default;


$bmd-label-color: #9e9e9e;
$bmd-label-color-inner-focus: #9e9e9e;

$white-color: #fff !default;

$bottombar-x: bottombar-x !default;
$margin-bottom: 0 0 10px 0 !default;
$spacer: 1rem !default;

$topbar-x: topbar-x !default;
$topbar-back: topbar-back !default;
$bottombar-x: bottombar-x !default;
$bottombar-back: bottombar-back !default;

// Sidebar variables
$sidebar-width: 260px !default;
$sidebar-mini-width: 80px !default;
