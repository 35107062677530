@mixin navbar-colors($color, $link-color) {
	// color: rgb(var($link-color));
	background-color: rgb(var(#{$color})) !important;
	@include shadow-navbar-color($color);

	.dropdown-item:hover,
	.dropdown-item:focus {
		@include shadow-small-color($color);
		background-color: rgb(var(#{$color}));
		color: rgb(var($link-color));
	}

	.navbar-toggler {
		.navbar-toggler-icon {
			background-color: rgb(var(#{$link-color}));
		}
	}
}

.navbar {
	border: 0;
	border-radius: $border-radius-base;
	padding: 0.625rem 0;
	margin-bottom: 20px;
	@include navbar-colors(--theme-text-highlight-colour, --theme-background-colour-test);


	// Hide .bread-crumbs if screen is less that 1000px
	@media (max-width: 1200px) {
		.bread-crumbs {
			display: none !important;
		}
	}


	&.fixed-top {
		border-radius: 0;
	}

	.navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}

	.navbar-nav {
		flex-direction: row;

		.nav-item .nav-link {
			position: relative;
			color: inherit;
			padding: $padding-general-x;
			font-weight: $font-weight-default;
			font-size: $mdb-btn-font-size-base;
			text-transform: uppercase;
			border-radius: $border-radius-base;
			line-height: 20px;

			&:not(.btn-just-icon) .fa {
				position: relative;
				top: 2px;
				margin-top: -4px;
				margin-right: 4px;
			}

			& .material-icons,
			& .fa {
				font-size: $font-size-lg;
				max-width: 24px;
				margin-top: -1.1em;
			}

			&:not(.btn) .material-icons {
				margin-top: -7px;
				top: 3px;
				position: relative;
				margin-right: 3px;
			}

			&.profile-photo {
				padding: 0;
				margin: 0 3px;

				&:after {
					display: none;
				}

				& .profile-photo-small {
					height: 40px;
					width: 40px;
				}

				.ripple-container {
					border-radius: 50%;
				}
			}
		}

		.dropdown-menu-right {
			transform-origin: 100% 0;
			position: absolute;
		}

		.nav-item.active .nav-link {

			&,
			&:hover,
			&:focus {
				color: inherit;
				background-color: rgba(255, 255, 255, 0.1);
			}
		}
	}

	.btn,
	.navbar-nav .nav-item .btn {
		margin-top: 0;
		margin-bottom: 0;
	}

	.navbar-toggler {
		cursor: pointer;
		outline: 0;

		.navbar-toggler-icon {
			width: 22px;
			height: 2px;
			vertical-align: middle;
			outline: 0;
			display: block;
			border-radius: 1px;

			&+.navbar-toggler-icon {
				margin-top: 4px;
			}
		}
	}

	&.navbar-absolute {
		position: absolute;
		width: 100%;
		padding-top: 10px;
		z-index: 29;
	}

	.navbar-wrapper {
		display: inline-flex;
		align-items: center;
	}

	// give correct size to ripple container
	.navbar-brand {
		position: relative;
		color: var(--theme-text-regular-colour-rgb);
		height: 50px;
		font-size: $navbar-brand-font-size - 0.125;
		line-height: 30px;
		padding: $padding-general-y 0;
		font-weight: 300;
		margin-left: 1rem;
	}

	>.container {
		flex: 1;
	}

	&.navbar-transparent {
		background-color: transparent !important;
		box-shadow: none;
		padding-top: 25px;

	}

	.notification {
		position: absolute;
		top: 5px;
		border: 1px solid #FFF;
		right: 10px;
		font-size: 9px;
		background: #f44336;
		color: #FFFFFF;
		min-width: 20px;
		padding: 0px 5px;
		height: 20px;
		border-radius: 10px;
		text-align: center;
		line-height: 19px;
		vertical-align: middle;
		display: block;
	}
}

.navbar {
	.navbar-minimize {
		padding: 3px 0 0 15px;
	}

	&.navbar-transparent {
		padding-top: 10px;
	}

	.collapse {
		.navbar-nav {
			.nav-item .nav-link {
				position: relative;
				padding: 10px 15px;
				font-weight: $font-weight-default;
				font-size: $mdb-btn-font-size-base;
				text-transform: uppercase;
				border-radius: $border-radius-base;
				line-height: 20px;
				margin-left: 5px;
				color: inherit;

				&:not(.btn-just-icon) .fa {
					position: relative;
					top: 2px;
					margin-top: -4px;
					margin-right: 4px;
				}

				& .material-icons,
				& .fa {
					font-size: $font-size-lg;
					max-width: 24px;
					margin-top: -1.1em;
				}

				&:not(.btn) .material-icons {
					margin-top: -3px;
					top: 0px;
					position: relative;
					margin-right: 3px;
				}

				.notification {
					top: 0px;
				}
			}
		}
	}
}

