@use "sass:math";

$bmd-invalid-underline: $brand-danger !default;
// $bmd-readonly-underline: $input-border-color !default;

//---
// verified in use with refactoring to v4

//---
//-- unverified below here
$bmd-brand-inverse: $indigo !default;
// Typography elements FIXME: review to see if we actually need these
$icon-color: rgba($black, 0.5) !default;

// --------------------
// inputs
$mdb-input-placeholder-color: #AAAAAA !default;
$mdb-input-underline-color: #D2D2D2 !default;

$mdb-input-font-size-base: 14px !default;

$bmd-bmd-label-static-size-ratio: math.div(75, 100) !default;
$bmd-help-size-ratio: math.div(75, 100) !default;

$bmd-form-control-bg-repeat-y: no-repeat !default;
$bmd-form-control-bg-position: center bottom, center calc(100% - 1px) !default;
$bmd-form-control-bg-size: 0 100%, 100% 100% !default;
$bmd-form-control-bg-size-active: 100% 100%, 100% 100% !default;

// expandable
$input-text-button-size: 32px !default;

$text-disabled: #a8a8a8 !default;
$background-disabled: #eaeaea !default;

$margin-base:               1.071rem !default;


// Checkboxes
$bmd-checkbox-size: 1.25rem !default;
$bmd-checkbox-animation-ripple: 500ms !default;
$bmd-checkbox-animation-check: 0.3s !default;
$bmd-checkbox-checked-color: $white !default;
$bmd-checkbox-label-padding: .3125rem !default; // 5px
$checkboxes-text-color: $mdb-input-placeholder-color !default;

$bmd-checkbox-border-size: .0625rem !default;
$bmd-checkbox-border-color: $bmd-label-color-inner-focus !default;
$bmd-checkbox-border-color-disabled: $gray-lighter !default; //#bdbdbd !default;

// Toggle
$mdb-toggle-label-color: $mdb-label-color !default;

// Variables for datetimepicker //
$padding-default-vertical:     10px !default;
$medium-pale-bg:             #F1EAE0 !default;
$pale-bg:                    #F9F7F3 !default;

$font-color:                 #66615b !default;
$black-color:           #3C4858 !default;

// Dropdowns

$dropdown-item-padding-y: .625rem;
$dropdown-item-padding-x: 1.25rem;
$dropdown-header-padding-y: 0.1875rem;
$bmd-dropdown-margin-y: .3125rem !default;

// Switches
$bmd-switch-label-padding: .3125rem !default; // 5px
$bmd-switch-width: 2.125rem !default; // 34px
$bmd-switch-height: .875rem !default; // 14px
$bmd-switch-handle-size: 1.25rem !default; // 20px (was 18px)

$bmd-switch-handle-unchecked-bg: #f1f1f1 !default;
$bmd-switch-handle-disabled-bg: #bdbdbd !default;
$bmd-switch-unchecked-bg: $gray-lighter !default;
$bmd-switch-disabled-bg: $gray-lighter !default;

$navbar-color: #555 !default;
$pills-color: $navbar-color !default;
$black-color: #3C4858 !default;
$white-transparent: rgba($white-color, .8);
$transparent: transparent;

//Paddings
$padding-general-y: 0.625rem !default;
$padding-general-x: 0.9375rem !default;
$padding-card-body-y: 0.9375rem !default;
$padding-card-body-x: 1.875rem !default;

// Buttons:
$mdb-btn-font-size-base: 12px !default;


$mdb-btn-icon-size: 32px !default;
$mdb-btn-icon-size-mini: 17px !default;

//Border-radius
$border-radius-base: 3px !default;
$border-radius-large: 6px !default;

// Animations
$general-transition-time:  300ms !default;

$slow-transition-time:           370ms !default;
$fast-transition-time:           150ms !default;

$transition-linear:                                   linear !default;

