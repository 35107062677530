$button-variants: (
	'primary': ('.btn-primary', '.mat-primary'),
	'secondary': ('.btn-secondary', '.mat-accent'),
	'tertiary': ('.btn-tertiary'),
	'success': ('.btn-success'),
	'warning': ('.btn-warning', '.mat-warn'),
	'danger': ('.btn-danger'),
	// 'default': ('', '.btn-default')
);

@mixin highlight {
	filter: saturate(120%) hue-rotate(5deg);
}

@mixin bmd-button-variant($color, $background, $border) {
	color: var(--#{$color});
	background-color: var(--#{$background});
	border: var(--#{$border});
	transition: all .3s;

	@include hover {
		@include highlight();
		color: arse;
	}

	&:focus,
	&.focus,
	&:hover {
		@include highlight();
	}

	&.disabled,
	&:disabled {

		&:focus,
		&.focus {
			@include highlight();
		}
	}
}

@mixin bmd-flat-button-variant($color,
	$border: $bmd-btn-border,
	$focus-border: $bmd-btn-focus-bg,
	$active-border: $bmd-btn-active-bg) {
	$background: $bmd-btn-bg;

	@include bmd-button-variant($color,
		$background,
		$border);

	// inverse color scheme
	.bg-inverse & {
		$focus-background: $bmd-inverse-btn-focus-bg;
		$focus-border: $bmd-inverse-btn-focus-bg;

		$active-background: $bmd-inverse-btn-active-bg;
		$active-border: $bmd-inverse-btn-active-bg;

		@include bmd-button-variant($color,
			$background,
			$border);
	}

	// reverse the above for links
	&.btn-link {
		background-color: transparent;
	}
}

@mixin bmd-flat-button-color() {
	@include bmd-flat-button-variant($bmd-btn-color);

	// flat bg with text color variations
	&.btn-primary {
		@include bmd-flat-button-variant($btn-primary-bg);
	}

	&.btn-secondary {
		@include bmd-flat-button-variant($btn-secondary-color);
	}

	&.btn-info {
		@include bmd-flat-button-variant($btn-info-bg);
	}

	&.btn-success {
		@include bmd-flat-button-variant($btn-success-bg);
	}

	&.btn-warning {
		@include bmd-flat-button-variant($btn-warning-bg);
	}

	&.btn-danger {
		@include bmd-flat-button-variant($btn-danger-bg);
	}
}


@mixin bmd-raised-button-variant($styles) {

	$color : 'theme-button-#{$styles}-colour';
	$background : 'theme-button-#{$styles}-bg';
	$border : 'theme-button-#{$styles}-border';

	@include bmd-button-variant($color, $background, $border);

	// @include shadow-2dp-color($background);

	&:focus,
	&:active,
	&:hover {
		// remove this line if you want black shadows
		@include button-shadow-color($background);
	}

	&.btn-link {
		background-color: transparent;
		color: var(--#{$background});
		box-shadow: none;

		&:hover,
		&:focus,
		&:active {
			background-color: transparent;
			color: var(--#{$background});
		}
	}
}

@mixin bmd-raised-button-color() {

	// Loop through each variant and apply the mixin
	@each $variant, $classes in $button-variants {
		@each $class in $classes {
			&#{$class} {
				@include bmd-raised-button-variant($variant);
			}
		}
	}
}

@mixin undo-bs-tab-focus() {

	// clear out the tab-focus() from BS
	&,
	&:active,
	&.active {

		&:focus,
		&.focus {
			//@include tab-focus();
			outline: 0;
		}
	}
}

@mixin shadow-big-navbar() {
	box-shadow: 0 10px 20px -12px rgba(0, 0, 0, $mdb-shadow-key-penumbra-opacity * 3),
		0 3px 20px 0px rgba(0, 0, 0, $mdb-shadow-ambient-shadow-opacity),
		0 8px 10px -5px rgba(0, 0, 0, $mdb-shadow-key-umbra-opacity);
}

@mixin shadow-big-color($color) {
	// new box shadow optimized for Tablets and Phones
	box-shadow: 0 4px 20px 0px rgba(0, 0, 0, .14),
		0 7px 10px -5px rgba($color, 0.4)
}

