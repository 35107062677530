:root.light-theme, :root.light-m3, :root.light-m2 {
	$brand-colors: (
		"theme-background-colour": #eee,
		"theme-background-colour-test": #eee,
		"theme-panel-background": #fff,
		"theme-pre-colour": #f5f5f5,
		"theme-text-highlight-colour": #000000,
		"theme-text-regular-colour": #333,
		"theme-text-faded-colour": #666,

		"theme-border-colour": #ccc,

		"theme-error-box-border": #f44336,
		"theme-error-box-text": #8e1d15,
		"theme-error-box-background": #ffe262,

		"theme-text-primary-colour": darken($brand-colour-1, 10%),
		"theme-text-accent1-colour": darken($brand-colour-2, 10%),

		"theme-text-link-colour": darken($brand-colour-1, 5%),
		"theme-shadow": #000,
		"theme-link":#3C4858,

		"theme-primary":  $brand-colour-1,
		"theme-accent1": $brand-colour-2,
		"theme-accent2": $brand-colour-3,
	);
@include generate-brand-colors($brand-colors);

.sidebar .logo a {
	background-image: url('/assets/img/inlights-light.svg');
}

.sidebar-background {
	background-image: url(/assets/img/sidebar-1.jpg)
}

--theme-alpha-brightness-correction: 1;

// Material
--theme-elevation-shadow: 0px 2px 1px -1px rgba(var(--theme-shadow), 0.2), 0px 1px 1px 0px rgba(var(--theme-shadow), 0.14), 0px 1px 3px 0px rgba(var(--theme-shadow), 0.12);
--theme-elevation-shadow-strong: 0px 5px 0px -3px rgba(var(--theme-shadow), 0.2), 0px 8px 10px 1px rgba(var(--theme-shadow), 0.14), 0px 0px 14px 0px rgba(var(--theme-shadow), 0.2);
--theme-drop-shadow: drop-shadow(0 3px 3px rgba(0, 0, 0, 0.075));

// Cards
--theme-card-primary-gradient: linear-gradient(60deg, #{darken($brand-colour-1, 0%)}, #{darken($brand-colour-1, 10%)});
--theme-card-accent-gradient: linear-gradient(60deg, #{darken($brand-colour-2, 5%)}, #{darken($brand-colour-2, 15%)});
--theme-card-info-gradient: linear-gradient(60deg, #{darken($brand-colour-3, 15%)}, #{darken($brand-colour-3, 25%)});
--theme-card-success-gradient: linear-gradient(60deg, #{$green-400}, #{$green-600});
--theme-card-warning-gradient: linear-gradient(60deg, #{$orange-600}, #{$orange-700});
--theme-card-danger-gradient: linear-gradient(60deg, #{$red-400}, #{$red-600});
}
