.inlights-form {
	&.justified {
		display: flex;
		flex-wrap: wrap;
		gap: 1em;
		container-type: inline-size;

		&>button {
			height: 4em; // if button in the nested directly in the row then make the same height as a field.
		}

		app-path {
		 display: contents;
		}

		mat-form-field,
		.form-field {
			box-sizing: border-box;
			width: 100%;

			@container (width > 400px) {
				flex: 1 1 15em;
				/* Grow, shrink, and set the initial width to 200px */
				min-width: 15em;
				max-width: 20em;

				// Loop through array of numbers [1,2,5,10,15,20,30,40,50] and create a class for each number
				@for $i from 1 through 50 {
					&.f-#{$i} {
						flex: 1 1 #{$i}em;
						min-width: #{$i}em;
					}

					&.t-#{$i} {
						max-width: #{$i}em;
					}
				}
			}
		}
	}
}

.form-section {
	&.flex {
		display: flex;
		flex-direction: row;
		gap: 1em;
		flex-wrap: wrap;
		align-items: start;

		mat-form-field {
			@media (min-width: 1000px) {
				flex: 1 0 21%;
			}
		}

		.form-field {
			display: flex;

			// if screen size is above 1000px wide
			@media (min-width: 1000px) {
				flex: 1 0 21%;
			}

			flex-direction: column;
			gap: 1em;
		}
	}

	margin: 1rem 0;

	mat-form-field {
		width: 100%;
	}

	.form-field {
		margin: 0.5em 0em;
	}
}

@mixin buttons($pos) {
	display: flex;
	justify-content: if($pos =='right', flex-end, flex-start);
	gap: 0.5em;
	margin-top: 1rem;
	width: 100%;

	.opp {
		display: flex;
		justify-content: if($pos =='right', flex-start, flex-end);
		gap: 0.5em;
		margin-#{if($pos == 'right', right, left)}: auto;
	}

	&.buttons-bar {
		// background-color: #c2c2c2;
		padding: 0.5em;
		border-radius: 0.5em;
		border: 1px solid #ccc;
		margin: 0;
	}
}

.buttons-right {
	@include buttons('right');
}

.buttons-left {
	@include buttons('left');
}

.ng-touched.ng-invalid,
.ng-submitted .ng-invalid {
	border-color: red;
}

.inlights-box {
	@for $i from 1 through 5 {
		&>h#{$i}:first-child {
			margin-top: 0;
		}
	}

	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	border: 1px solid var(--theme-dividers-and-borders);
	border-radius: 5px;
	width: 100%;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
}

fieldset {
	border: 1px solid var(--theme-dividers-and-borders);
	border-radius: 5px;
	padding: 20px;
	margin-top: 20px;
	margin-bottom: 20px;
}

.file-list-exists {
	display: flex;
	gap: 0.5em;
	flex-direction: row;
	flex-wrap: wrap;

	&>* {
		flex-grow: 1;
		min-width: 25%;
	}
}

.validation-summary {
	border: 1px solid rgb(var(--theme-error-box-border));
	border-radius: 5px;
	padding: 20px;
	margin-top: 20px;
	margin-bottom: 20px;
	background-color: rgb(var(--theme-error-box-background));
	color: rgb(var(--theme-error-box-text));

	table {
	 border-collapse: collapse;
		th {
			padding: 0 5px;
		 border: solid 1px rgb(var(--theme-error-box-text));
	 }

		td {
			padding: 0 5px;
		 border: solid 1px rgb(var(--theme-error-box-text));
		}
	}
}


.action-buttons {
	display: flex;
	gap: 0.5em;
	justify-content: end;
}


fieldset {
	border: 1px solid $brand-accent;
	border-radius: 0.25em;
	padding: 1em;
	margin-bottom: 1em;


	&>legend {
		color: $brand-accent;
		font-weight: 600;
		width: auto;
		padding-right: 0.5em;
		padding-left: 0.25em;
		margin-bottom: 0;
		font-size: 1em;

		&>mat-checkbox {
			label {
				color: $brand-accent;
				margin: 0;
			}
		}
	}

	.field-set-content {
		transition: max-height 0.25s ease-out;
		max-height: 1000px;
		overflow: hidden;
	}

	&.closed {
		padding: 0;
		border-color: transparent;
		margin: 0;

		// hide all content inside the fieldset except the legend
		&>*:not(legend):not(.field-set-content) {
			display: none !important;
		}

		.field-set-content {
			max-height: 0;
		}
	}

	transition: all 0.5s;
}


mat-checkbox {
	label {
		margin: 0;
	}
}

.fields-flex {
	display: flex;
	flex-wrap: wrap;
	gap: 0.5em;
	flex-direction: row;
	align-items: center;

	mat-form-field {
		width: 15em;
	}
}


.file-input-box {
	color: rgb(180, 0, 0);

	.download-button {
		display: none;
	}

	&.exists {
		color: green;

		// background-color: rgb(215, 255, 214);
		.download-button {
			display: initial;
		}
	}

	&.ng-invalid {
		border-color: var(--theme-error);
		// background-color: #fff876;
	}
}

.file-item {
	border: solid 1px var(--theme-dividers-and-borders);
	padding: 0.5em;
	border-radius: 0.5em;
	display: flex;
	align-items: center;
	gap: 0.5em;
	justify-content: start;
	color: rgb(180, 0, 0);
	background-color: #d6d6d6;

	label {
		margin: 0;
		color: var(--theme-text-regular-colour);
	}

	&.exists {
		color: green;
		background-color: rgb(215, 255, 214);
	}

	&.ng-invalid {
		border-color: var(--theme-error);
		background-color: #fff876;
	}
}
