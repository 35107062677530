table.mat-mdc-table {
	thead {
		tr {
			th {
				font-weight: 600;
				color: rgb(var(--theme-text-primary-colour));
			}
		}
	}

	tbody {
		tr {
			td.table-actions-cell {
				text-align: right;
			}
		}
	}
}
