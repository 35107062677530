// Bootstrap brand color customization


/*     brand Colors              */

$brand-primary: $indigo-700 !default;
$brand-accent: $deep-purple-400 !default;
$brand-info: $cyan-500 !default;
$brand-success: $green-500 !default;
$brand-warning: $orange-500 !default;
$brand-danger: $red-500 !default;
$brand-rose: $pink-500 !default;
$brand-inverse: $black-color !default;
