@mixin shadow-big() {
	box-shadow: 0 16px 38px -12px rgba(0, 0, 0, $bmd-shadow-penumbra-opacity * 4),
		0 4px 25px 0px rgba(0, 0, 0, $bmd-shadow-ambient-opacity),
		0 8px 10px -5px rgba(0, 0, 0, $bmd-shadow-umbra-opacity);
}

@mixin shadow-big-image() {
	// new box shadow optimized for Tables and Phones
	box-shadow: 0 5px 15px -8px rgba(0, 0, 0, $bmd-shadow-ambient-opacity * 2),
		0 8px 10px -5px rgba(0, 0, 0, $bmd-shadow-umbra-opacity);
}

@mixin shadow-big-navbar() {
	box-shadow: 0 10px 20px -12px rgba(0, 0, 0, $bmd-shadow-penumbra-opacity * 3),
		0 3px 20px 0px rgba(0, 0, 0, $bmd-shadow-ambient-opacity),
		0 8px 10px -5px rgba(0, 0, 0, $bmd-shadow-umbra-opacity);
}

// @mixin shadow-big-color($color){
//     // new box shadow optimized for Tables and Phones
//     box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
//     0 13px 24px -11px rgba($color, 0.60);
// }

@mixin shadow-small-color($color) {
	// new box shadow optimized for Tablets and Phones
	box-shadow: 0 4px 20px 0px rgba(0, 0, 0, .14),
		0 7px 10px -5px rgba(var(#{$color}), 0.4)
}

@mixin shadow-navbar-color($color) {
	// new box shadow optimized for Tablets and Phones
	box-shadow: 0 4px 20px 0px rgba(0, 0, 0, .14),
	0 7px 12px -5px rgba(var(#{$color}), 0.46);
}

@mixin shadow-2dp() {
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, $bmd-shadow-penumbra-opacity),
		0 3px 1px -2px rgba(0, 0, 0, $bmd-shadow-ambient-opacity),
		0 1px 5px 0 rgba(0, 0, 0, $bmd-shadow-umbra-opacity);
}

@mixin shadow-8dp() {
	box-shadow: 0 8px 10px 1px rgba(0, 0, 0, $bmd-shadow-penumbra-opacity),
		0 3px 14px 2px rgba(0, 0, 0, $bmd-shadow-ambient-opacity),
		0 5px 5px -3px rgba(0, 0, 0, $bmd-shadow-umbra-opacity);
}

@mixin button-shadow-color($color) {
	box-shadow: 0 14px 26px -12px rgba(var(--#{$color}), $bmd-shadow-penumbra-opacity * 3),
		0 4px 23px 0px rgba(0, 0, 0, $bmd-shadow-ambient-opacity),
		0 8px 10px -5px rgba(var(--#{$color}), $bmd-shadow-umbra-opacity);
}
