// mdc-button
// mdc-button--raised
// mat-mdc-raised-button
// mat-primary
// mat-mdc-button-base
// cdk-focused
// cdk-mouse-focused
@mixin bmd-disabled() {
  fieldset[disabled][disabled] &,
  &.disabled,
  &:disabled,
  &[disabled] {
    @content;
  }
}

.mdc-button.mat-mdc-button-base,
.btn {
	position: relative;
	padding: 12px 30px;
	margin: $bmd-btn-margin-bottom 1px;
	min-width: auto;
	font-size: .75rem; //  12px
	font-weight: 400;
	line-height: 1.42857143;
	text-decoration: none;
	text-transform: uppercase;
	vertical-align: middle;
	letter-spacing: 0;
	cursor: pointer;
	background-color: transparent;
	border: 0;
	border-radius: $border-radius-sm;
	outline: 0;
	transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
	will-change: box-shadow, transform;
	@include undo-bs-tab-focus();

	&.mat-mdc-button-disabled {
		opacity: 0.7;
		filter: grayscale(0.5);
	}

	//--
	// Colors
	@include bmd-raised-button-color();
	@include hover-focus();
}

.input-group-btn>.btn {
	border: 0;
}

// Align icons inside buttons with text
.btn .material-icons,
.btn:not(.btn-just-icon):not(.btn-fab) .fa {
	position: relative;
	display: inline-block;
	top: 0;
	margin-top: -1em;
	margin-bottom: -1em;
	font-size: 1.1rem;
	vertical-align: middle;
}

// Disabled buttons and button groups
.mat-raised-button.btn,
.input-group-btn .mat-raised-button.btn,
.btn-group,
.btn-group-vertical {

	// have to ratchet up the specificity to kill drop shadows on disabled raised buttons
	@include bmd-disabled() {
		.bg-inverse & {
			color: $bmd-inverse-btn-disabled;
		}

		// flat buttons shouldn't lose transparency on disabled hover/focus
	}
}

.mat-button,
.mat-icon-button {
	background: transparent;
}

.mat-button:hover .mat-button-focus-overlay,
.mat-stroked-button:hover .mat-button-focus-overlay {
	opacity: 0;
	background-color: transparent !important;
}

button:focus {
	outline: none;
}

.mat-button .mat-button-ripple {
	border-radius: inherit;
}
