html,
body {
	height: 100%;
	position: relative;
}

body {
	margin: 0;
	font-family: Roboto, 'Helvetica Neue', sans-serif;
	position: relative;
}

.w-full {
	width: 100%;
}

.no-data-message {
	padding: 1em;
	text-align: center;
}

.router-link-active {
	color: $brand-accent;

	&:hover {
		color: $brand-accent;
	}

	&:last-child {
		color: var(--theme-text-link-colour-rgb);
		font-weight: 500;

		&:active,
		&:visited,
		&:hover,
		&:focus {
			color: var(--theme-text-link-colour-rgb);
		}
	}
}

.loading-popup-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	animation: fadeIn 0.5s;
}

.toolbar {
	display: flex;
	flex-direction: row;
	gap: 1em;
	align-items: center;
}

.bg {
	&-transparent {
		background-color: white !important;
		background-image:
			linear-gradient(45deg, #ccc 25%, transparent 25%),
			linear-gradient(-45deg, #ccc 25%, transparent 25%),
			linear-gradient(45deg, transparent 75%, #ccc 75%),
			linear-gradient(-45deg, transparent 75%, #ccc 75%);
		background-size: 20px 20px;
		background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
	}
}

.loading-content {
	animation: fadeIn 1s;
	top: 15vh;
	left: 0;
	width: 100%;
	position: absolute;
	text-align: center;

	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: center;

	h1 {
		color: white;
		margin: 0;
		font-size: 2.5vw;
		// preserve spaces
		text-wrap: nowrap;
		;
		border: solid 1px rgba(127, 127, 127, 0.253);
		background-color: rgba(96, 93, 121, 0.356);
		border-radius: 10px;
		padding: 10px 10px;
		width: 80vw;
	}

	.animated-waiting {
		margin: auto;
		width: 8vw;
		height: 8vw;
		display: block;
		color: white;
	}
}

.filters-bar {
	display: flex;
	flex-wrap: wrap;

	&.lift-to-title {
		margin: -15px -5px 0 -5px;

		mat-form-field {
			&>div:first-child {
				border-radius: 0 0 5px 5px;
			}

			&:has(+ mat-form-field) {
				&>div:first-child {
					border-bottom-right-radius: 0;
				}
			}

			&:not(:first-child) {
				&>div:first-child {
					border-bottom-left-radius: 0;
				}
			}

			.mdc-line-ripple {
				display: none;
			}
		}
	}

	mat-form-field {
		flex-grow: 1;

		&:has(+ mat-form-field) {
			&>div:first-child {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				border-right: 1px solid var(--mdc-filled-text-field-active-indicator-color);
			}
		}

		&:not(:first-child) {
			&>div:first-child {
				border-top-left-radius: 0;
			}
		}
	}

	label {
		font-weight: 500;
	}
}

.mat-mdc-tooltip-surface {
	white-space: pre-line;
}

.list-with-side-warning {
	.list-item {
		margin-bottom: 10px;

		display: grid;
		grid-template-columns: 150px 350px 1fr;
		grid-template-areas: "label field warning";

		justify-content: stretch;
		align-items: stretch;


		column-gap: 10px;

		&>.label {
			grid-area: label;
			font-weight: 500;
			padding: 0;
			margin: 0;
			background-color: rgb(var(--theme-background-colour));
			display: flex;
			align-items: center;
			justify-content: end;
			padding: 5px;

			label {
				color: rgb(var(--theme-text-highlight-colour));
			}
		}

		&>mat-form-field {
			grid-area: field;
			flex-grow: 1;
		}

		&>.warning-side-box {
			grid-area: warning;
			border: solid 1px rgb(var(--theme-error-box-border));
			background-color: rgb(var(--theme-error-box-background));
			color: rgb(var(--theme-error-box-text));
			display: flex;
			align-items: center;
			padding: 5px;
		}

	}
}

app-mini-loader {
	display: block;
}
