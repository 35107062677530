$main-panel-width: calc(100% - $sidebar-width) !default;
$main-panel-width-min-sb: calc(100% - $sidebar-mini-width) !default;

.wrapper {
	position: relative;
	top: 0;
	height: 100vh;
}

.sidebar {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 2;
	width: $sidebar-width;
	background: var(--theme-panel-background-rgb);
	@include shadow-big();

	.caret {
		display: inline-block;
		width: 0;
		height: 0;
		margin-left: 2px;
		vertical-align: middle;
		border-top: 4px dashed;
		border-top: 4px solid\9;
		border-right: 4px solid transparent;
		border-left: 4px solid transparent;
	}

	&[data-background-color="black"] {
		background-color: #191919;
	}

	.logo-img {
		width: 35px;
		display: block;
		max-height: 30px;
		margin-left: 13px;
		margin-right: 15px;

		img {
			width: 35px;
			top: 16px;
			position: absolute;
		}
	}

	.sidebar-wrapper {
		position: relative;
		height: calc(100vh - 75px);
		overflow: auto;
		width: $sidebar-width;
		z-index: 4;

		padding-bottom: 30px;

		.dropdown .dropdown-backdrop {
			display: none !important;
		}

		.navbar-form {
			border: none;
			box-shadow: none;

			.input-group {
				font-size: 1.7em;
				height: 36px;
				width: 78%;
				padding-left: 17px;
			}
		}

		>.nav,
		.user .user-info {

			[data-toggle="collapse"]~div>ul>li>a {
				span {
					display: inline-block;
					@extend .animation-transition-general;
				}

				.sidebar-normal {
					margin: 0;
					position: relative;
					transform: translateX(0px);
					opacity: 1;
					white-space: nowrap;
					display: block;

				}

				.sidebar-mini {
					text-transform: uppercase;
					width: 30px;
					margin-right: 15px;
					text-align: center;
					letter-spacing: 1px;
					position: relative;
					float: left;
					display: inherit;
				}

				i {
					font-size: 17px;
					line-height: 20px;
					width: 26px;
				}
			}
		}
	}

	.logo-tim {
		border-radius: 50%;
		border: 1px solid var(--theme-text-regular-colour-rgb);
		display: block;
		height: 61px;
		width: 61px;
		float: left;
		overflow: hidden;

		img {
			width: 60px;
			height: 60px;
		}
	}

	.nav {
		margin-top: 20px;
		display: block;

		.caret {
			margin-top: 13px;
			position: absolute;
			right: 6px;
		}

		li {

			>a {

				&:hover,
				&:focus {
					background-color: transparent;
					outline: none;
				}

				display: flex;
				align-items: center;

				p {
					margin: 0;
					font-size: 14px;
					white-space: nowrap;
					line-height: 30px;
				}

				i,
				mat-icon {
					margin-right: 15px;
					width: 30px;
					text-align: center;
					color: #a9afbb;
					scale: 1.25;
				}
			}

			&:first-child>a {
				margin: 0 15px;
			}

			&:hover>a,
			& .dropdown-menu a:hover,
			& .dropdown-menu a:focus,
			&.active>[data-toggle="collapse"] {
				background-color: rgba(200, 200, 200, 0.2);
				color: var(--theme-text-highlight-colour-rgb);
				box-shadow: none;
			}

			&.active>[data-toggle="collapse"] {
				i {
					color: #a9afbb;
				}
			}

			&.active>a {
				color: var(--theme-text-highlight-colour-rgb);

				mat-icon {
					color: white;
				}
			}

			&.separator {
				margin: 15px 0;

				&:after {
					width: calc(100% - 30px);
					content: "";
					position: absolute;
					height: 1px;
					left: 15px;
					background-color: rgba(180, 180, 180, .3);
				}

				&+li {
					margin-top: 31px;
				}
			}
		}


	}

	.nav li a,
	.nav li .dropdown-menu a {
		margin: 10px 15px 0;
		border-radius: $border-radius-base;
		color: var(--theme-text-highlight-colour-rgb);
		padding-left: 10px;
		padding-right: 10px;
		text-transform: capitalize;
		font-size: $font-paragraph - 1;
		padding: 10px 15px;
	}


	.sidebar-background {
		position: absolute;
		z-index: 1;
		height: 100%;
		width: 100%;
		display: block;
		top: 0;
		left: 0;
		background-color: var(--theme-panel-background-rgb);
		background-size: cover;
		background-position: center center;

		background-size: 100% auto;
		background-position: bottom center;
		background-repeat: no-repeat;

		&:after {
			position: absolute;
			z-index: 3;
			width: 100%;
			height: 100%;
			content: "";
			display: block;
			background: var(--theme-panel-background-rgb);
			opacity: .45;
		}
	}

	.logo {
		padding: 15px 0px;
		margin: 0;
		display: block;
		position: relative;
		z-index: 4;

		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			right: 15px;
			height: 1px;
			width: calc(100% - 30px);
			background-color: rgba(180, 180, 180, .3);

		}

		a {
			display: flex;
			justify-content: center;

			background-image: url('/assets/img/inlights-light.svg');
			background-size: 85%;
			background-repeat: no-repeat;
			background-position: center;
			height: 80px;

			img {
				display: none;
				width: 80%;
			}
		}
	}

	.logo-tim {
		border-radius: 50%;
		border: 1px solid var(--theme-text-regular-colour-rgb);
		display: block;
		height: 61px;
		width: 61px;
		float: left;
		overflow: hidden;

		img {
			width: 60px;
			height: 60px;
		}
	}

	li {
		&>a {
			background-color: rgba(var(--theme-panel-background), 0.6);
			outline: solid 1px var(--theme-panel-background-rgb);

		}

		&.active {
			&>a {
				outline: none;
				z-index: 2;
				position: relative;
			}
		}
	}


	&[data-background-color="black"] {
		@include sidebar-background-color($gray-base, var(--theme-panel-background-rgb));

		.nav li .dropdown-menu .dropdown-item {
			color: var(--theme-panel-background-rgb);
		}
	}

	&[data-color="primary"] {
		@include set-background-color-button(--theme-card-primary-gradient);
		outline: none;
		@include sidebar-active-color(--theme-card-primary-text, --theme-card-primary);
	}

	&[data-color="azure"] {
		@include set-background-color-button(--theme-card-accent-gradient);
		@include sidebar-active-color(--theme-card-accent-text, --theme-card-accent);
	}

	&[data-color="green"] {
		@include set-background-color-button(--theme-card-success-gradient);
		@include sidebar-active-color(--theme-card-success-text, --theme-card-success);
	}

	&[data-color="orange"] {
		@include set-background-color-button(--theme-card-warning-gradient);
		@include sidebar-active-color(--theme-card-warning-text, --theme-card-warning);
	}

	&[data-color="danger"] {
		@include set-background-color-button(--theme-card-danger-gradient);
		@include sidebar-active-color(--theme-card-danger-text, --theme-card-danger);
	}

	&[data-color="rose"] {
		@include set-background-color-button(--theme-card-rose-gradient);
		@include sidebar-active-color(--theme-card-rose-text, --theme-card-rose);
	}

	&[data-background-color="red"] {
		@include sidebar-background-color($brand-danger, var(--theme-panel-background-rgb));

		.user,
		.logo,
		.nav li.separator {
			&:after {
				background-color: rgba(255, 255, 255, .3);
			}
		}

		.nav {

			li:hover:not(.active)>a,
			li.active>[data-toggle="collapse"] {
				background-color: rgba(255, 255, 255, 0.1);
			}
		}
	}

	&[data-image]:after,
	&.has-image:after {
		opacity: .77;
	}
}

.main-panel {
	position: relative;
	float: right;
	width: $main-panel-width;
	@include transition (0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));

	.main-content {
		margin-top: 48px;
		padding: 30px 15px;
		min-height: calc(100vh - 123px);

		&.fixed-height {
			height: calc(100vh - 48px);

			// background-color: pink;
			.container-fluid {
				height: 100%;
			}
		}
	}

	.footer {
		border-top: 1px solid #e7e7e7;
	}

	.navbar {
		margin-bottom: 0;
	}

	.header {
		margin-bottom: 30px;

		.title {
			margin-top: 10px;
			margin-bottom: 10px;
		}
	}
}

.perfect-scrollbar-on {

	.sidebar,
	.main-panel {
		height: 100%;
		max-height: 100%;
	}
}


.sidebar,
.main-panel,
.sidebar-wrapper {
	-webkit-transition-property: top, bottom, width;
	transition-property: top, bottom, width;
	-webkit-transition-duration: .2s, .2s, .35s;
	transition-duration: .2s, .2s, .35s;
	-webkit-transition-timing-function: linear, linear, ease;
	transition-timing-function: linear, linear, ease;
	-webkit-overflow-scrolling: touch;
}

.visible-on-sidebar-regular {
	display: inline-block !important;
}

.visible-on-sidebar-mini {
	display: none !important;
}

@media (min-width: 991px) {
	.sidebar-mini {
		.visible-on-sidebar-regular {
			display: none !important;
		}

		.visible-on-sidebar-mini {
			display: inline-block !important;
		}

		.sidebar,
		.sidebar .sidebar-wrapper {
			width: $sidebar-mini-width;
		}

		.main-panel {
			width: $main-panel-width-min-sb;
		}

		.sidebar {
			display: block;
			font-weight: 200;
			z-index: 99;

			.logo {
				a.logo-normal {
					opacity: 0;
					@include transform-translate-x(-25px);
				}
			}

			.sidebar-wrapper {

				>.nav [data-toggle="collapse"]~div>ul>li>a .sidebar-normal,
				.user .user-info [data-toggle="collapse"]~div>ul>li>a .sidebar-normal,
				.user .user-info>a>span,
				>.nav li>a p {
					@include transform-translate-x(-25px);
					opacity: 0;
				}
			}
		}

		.sidebar:hover {
			width: 260px;

			.logo {
				a.logo-normal {
					opacity: 1;
					@include transform-translate-x(0px);
				}
			}

			.sidebar-wrapper {
				width: 260px;

				>.nav li>a p,
				>.nav [data-toggle="collapse"]~div>ul>li>a .sidebar-normal,
				.user .user-info [data-toggle="collapse"]~div>ul>li>a .sidebar-normal,
				.user .user-info>a>span {
					@include transform-translate-x(0px);
					opacity: 1;
				}
			}
		}
	}

	.sidebar {
		.nav .nav-item {
			&.active-pro {
				position: absolute;
				width: 100%;
				bottom: 13px;
			}
		}
	}
}
