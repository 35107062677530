:root {
	$brand-colors: (
		"brand-primary": $brand-primary,
		"brand-accent": $brand-accent,
		"brand-info": $brand-info,
		"brand-success": $brand-success,
		"brand-warning": $brand-warning,
		"brand-danger": $brand-danger,
		"brand-rose": $brand-rose,
		"brand-inverse": $brand-inverse,

		// Variouse material design colours
		"bmd-dropdown-header-color": #777,
		"bmd-dropdown-link-color": #333,

		// Cards
		"theme-card-primary": $brand-colour-1,
		"theme-card-primary-text": #ffffff,
		"theme-card-accent": $brand-colour-2,
		"theme-card-accent-text": #ffffff,
		"theme-card-info": $brand-colour-3,
		"theme-card-info-text": #ffffff,
		"theme-card-success": $green-400,
		"theme-card-success-text": #ffffff,
		"theme-card-warning": $orange-400,
		"theme-card-warning-text": #ffffff,
		"theme-card-danger": $red-400,
		"theme-card-danger-text": #ffffff,
		"theme-card-rose": $pink-400,
		"theme-card-rose-text": #ffffff,
	);
	@include generate-brand-colors($brand-colors);

	.sidebar-background {
		background-image: url(/assets/img/sidebar-1.jpg)
	}


// Cards
--theme-card-primary-gradient: linear-gradient(60deg, #{darken($brand-colour-1, 0%)}, #{darken($brand-colour-1, 10%)});
--theme-card-accent-gradient: linear-gradient(60deg, #{darken($brand-colour-2, 5%)}, #{darken($brand-colour-2, 15%)});
--theme-card-info-gradient: linear-gradient(60deg, #{darken($brand-colour-3, 5%)}, #{darken($brand-colour-3, 15%)});
--theme-card-success-gradient: linear-gradient(60deg, #{$green-400}, #{$green-600});
--theme-card-warning-gradient: linear-gradient(60deg, #{$orange-400}, #{$orange-600});
--theme-card-danger-gradient: linear-gradient(60deg, #{$red-400}, #{$red-600});
--theme-card-rose-gradient: linear-gradient(60deg, #{$pink-400}, #{$pink-600});

}
