:root.dark-theme, :root.dark-m3, :root.dark-m2 {
	$brand-colors: (
		"theme-background-colour": #393939,
		"theme-background-colour-test": #393939,
		"theme-panel-background": #000,
		"theme-pre-colour": #333,
		"theme-text-highlight-colour": #fff,
		"theme-text-regular-colour": #dbdbdb,
		"theme-text-faded-colour": #8f8f8f,

		"theme-border-colour": #333,

		"theme-text-primary-colour": lighten($brand-colour-1, 40%),
		"theme-text-accent1-colour": lighten($brand-colour-2, 40%),

		"theme-error-box-border": #ff9100,
		"theme-error-box-text": #ffffff,
		"theme-error-box-background": #907300,


		"theme-text-link-colour": lighten($brand-colour-1, 30%),
		"theme-shadow": #fff,
		"theme-link": #fff,

		"theme-primary": darken($brand-colour-1, 10%),
		"theme-accent1": darken($brand-colour-2, 10%),
		"theme-accent2": $brand-colour-3,
		"theme-selected-label-col" : lighten($brand-colour-1, 30%),
	);
@include generate-brand-colors($brand-colors);

body {
  scrollbar-color: var(--theme-panel-background-rgb) var(--theme-background-colour-rgb);
	// scrollbar-width: 5px;
}

.sidebar .logo a {
	background-image: url('/assets/img/inlights-dark.svg');
}

.sidebar-background {
	background-image: url('/assets/img/sidebar-dark.svg');
}

--theme-alpha-brightness-correction: 2;

// Material
--theme-elevation-shadow: 0px 0px 0px 2px rgba(var(--theme-shadow), 0.2);
// --theme-elevation-shadow: 0px 5px 0px -3px rgba(var(--theme-shadow), 0.1), 0px 8px 10px 1px rgba(var(--theme-shadow), 0.1), 0px 0px 14px 0px rgba(var(--theme-shadow), 0.1);
--theme-elevation-shadow-strong: 0px 0px 0px 3px rgba(var(--theme-shadow), 0.3);
--theme-drop-shadow: drop-shadow(0 3px 3px rgba(255, 255, 255, 0.25));

// Cards
--theme-card-primary-gradient: linear-gradient(60deg, #{darken($brand-colour-1, 5%)}, #{darken($brand-colour-1, 15%)});
--theme-card-accent-gradient: linear-gradient(60deg, #{darken($brand-colour-2, 15%)}, #{darken($brand-colour-2, 25%)});
--theme-card-info-gradient: linear-gradient(60deg, #{darken($brand-colour-3, 15%)}, #{darken($brand-colour-3, 25%)});
--theme-card-success-gradient: linear-gradient(60deg, #{$green-400}, #{$green-600});
--theme-card-warning-gradient: linear-gradient(60deg, #{$orange-600}, #{$orange-700});
--theme-card-danger-gradient: linear-gradient(60deg, #{$red-400}, #{$red-600});
--theme-card-rose-gradient: linear-gradient(60deg, #{$pink-400}, #{$pink-600});

}
